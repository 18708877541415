<template>
<div id="app" style="padding:15px">
    <el-row style="z-index:999">
      <el-col :span="24">
        <el-page-header @back="goBack" content="CRT点位展示"> </el-page-header>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="z-index:999">
        <el-col :span="12" :offset="0">
            <el-select v-model="crtId" @change="crtInfoChanged" filterable placeholder="请选择">
                <el-option v-for="item in crtInfoList" :ref="item.id" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-col>
        <!-- <el-col :span="2"> <div class="iconfont icon-guanbi close"  @click="closePage"></div></el-col> -->
    </el-row>
    <el-row :gutter="20">
        <el-col :span="19" :offset="0">
            <el-row :gutter="0">
                <el-col :span="24" :offset="0">
                    <div id="overburden" @wheel.prevent="bbimg" v-drag>
                        <img v-for="item in crtDeviceCoorcrtDinateList" :id="item.deviceInfoId" :src="baseUrl+item.iconUrl" :style="{'left':(parseFloat(item.coordinatex)*crtWid-parseFloat(item.iconWidth)/2).toString()+'px','top':(parseFloat(item.coordinatey)*crtHei-parseFloat(item.iconWidth)/2).toString()+'px','width':item.iconWidth+'px','position':'absolute','z-index':'2','cursor':'pointer'}" @click="showDeviceInfo(item)"></img>
                        <img v-if="crtId!=''" id="crt" :src="baseUrl+crtUrl"></img>
                    </div>
                    <div v-if="crtId==''" id="tempText">请选择CRT...</div>
                </el-col>
            </el-row>
        </el-col>
        <el-col :span="5" :offset="0">
            <el-row :gutter="20">
                <el-col :span="12" :offset="0">
                    <el-input v-model="addedDeviceInfoQuery.id" placeholder="设备ID" size="normal" @input="getAddedDeviceInfoList" clearable></el-input>
                </el-col>
                <el-col :span="12" :offset="0">
                    <el-input v-model="addedDeviceInfoQuery.typeName" placeholder="设备类型" size="normal" @input="getAddedDeviceInfoList" clearable></el-input>
                </el-col>
            </el-row>
            <el-row :gutter="0">
                <el-col :span="24" :offset="0">
                    <el-table :data="addedDeviceInfoList" style="width: 100%">
                        <el-table-column prop="id" label="设备编号" width="110" align="center"></el-table-column>
                        <el-table-column prop="typeName" label="设备类型" width="100"  align="center"></el-table-column>
                        <el-table-column label="定位" width="170" align="center">
                            <template slot-scope="scope">
                                <el-button @click="handlePosition(scope.row.id)" @blur="recoveryPosition(scope.row.id)" type="danger" size="small">定位</el-button>
                                <el-button @click="recoveryPosition(scope.row.id)" type="danger" size="small">取消定位</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-col>
    </el-row>

    <el-dialog title="设备信息详情"  :visible.sync="deviceInfoDialogVisible" width="60%" @close="clearDeviceInfo">
        <el-form label-width="120px" :model="deviceInfo" class="item-form" >
          <!-- 动态 -->
            <el-card class="greenForm item-card" v-for="item in deviceValueInfoList" :class="item.value.indexOf('故障')!=-1&&item.value.indexOf('无故障')<0? 'yellow' :item.value.indexOf('报警')!=-1 || (item.value.indexOf('火警')!=-1 && item.value.indexOf('无火警')==-1 ) ? 'red' : item.value.indexOf('失联')!=-1 ? 'gery': 'green'">
               <b>{{item.id}}</b> <br>{{item.value}}
             </el-card>
            <!-- 静态 -->
            <el-card class="item-card">
               <b>设备ID</b> <br>{{deviceInfo.id}}
            </el-card>
            <el-card class="item-card">
               <b>设备类型</b> <br>{{deviceInfo.typeName}}
            </el-card>

            <el-card v-for="item in deviceInfo.device" class="item-card">
               <b>{{item.proName}}</b> <br>{{ item.proName == "绑定公司"?companyNameById[item.value] :item.proName == "所属系统"? systemNameById[item.value] : item.value }}
            </el-card>

        </el-form>
        <div slot="footer" class="dialog-footer"> 
            <el-button @click="toHistory" type="primary">历史记录</el-button>
            <el-button type="primary" @click="showVideo" :type="deviceInfo.videoUrl!=null?'success':'warning'">查看视频</el-button>
        </div>
    </el-dialog>

</div>
</template>

<script>
import companySystem from "@/api/managementApi/CompanyInfo";
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";

import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";
import CRT from "@/api/manageApi/CrtInfo";
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import BaseData from "@/assets/config/BaseData.js";
import drag from "@/../utils/drag.js";
export default {
  data() {
    return {
      companyNameById: {},
      systemNameById: {},
      //crt信息列表
      crtInfoList: [],
      deviceId: "",
      //当前选中的crt对象
      crtId: "",
      crtUrl: "",
      //点位列表
      crtDeviceCoorcrtDinateList: [],
      addedDeviceInfoList: [],
      //添加点位弹框内模糊查询
      deviceInfoQuery: {
        id: "",
        typeName: "",
      },
      addedDeviceInfoQuery: {
        id: "",
        typeName: "",
        crtPictureId: "",
      },
      //设备列表当前选中
      currentRow: null,
      //设备信息详情
      deviceInfo: {},

      //当前crt图的宽度
      crtWid: 0,
      //当前crt图的高度
      crtHei: 0,
      //图片虚拟路径前缀，用于访问服务器以获取图片
      baseUrl: BaseData.baseUrl,
      //设备信息详情弹框标志位
      deviceInfoDialogVisible: false,

      //设备动态属性
      deviceValueInfoList: [],
    };
  },
  created() {
    this.init();
    this.getCompanyInfo();
    this.jurisSysName();
  },
  mounted() {},
  watch: {
    crtId(newVal, oldVal) {},
  },
  methods: {
    // 所有公司
    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        console.log("------------------");
        for (let i in res.data.data.list) {
          this.companyNameById[res.data.data.list[i]["id"]] =
            res.data.data.list[i]["name"];
        }
        console.log(this.companyNameById);
        console.log("---------------------");
      });
    },

    // 获取权限系统名称
    jurisSysName() {
      noticeSysManagement.getNotifyBigSystemById().then((res) => {
        this.systemNameById = {};
        this.permissionsSystemName = res.data.data.list;
        for (let i in this.permissionsSystemName) {
          this.systemNameById[this.permissionsSystemName[i].id] =
            this.permissionsSystemName[i].bigSystemName;
        }
        console.log(this.systemNameById);
      });
    },

    goSelect() {
      this.$router.push("/Select");
    },
    closePage() {
      window.close();
    },
    init() {
      this.getCrtInfoList();
    },
    toHistory() {
      this.$router.push({
        path: "/History",
      });
    },
    //视频
    showVideo() {
      if (this.deviceInfo.videoUrl) {
        window.location.href = this.deviceInfo.videoUrl;
      } else {
        //提示信息
        this.$message({
          type: "warning",
          message: "该设备未绑定视频设备！",
        });
      }
    },
    // 條件查询全部已绑定点位的设备信息
    getAddedDeviceInfoList() {
      deviceInfoManagement
        .getAddedDeviceInfoList(this.addedDeviceInfoQuery)
        .then((res) => {
          this.addedDeviceInfoList = res.data.data.list;
        });
    },
    // 定位已绑定点位的设备
    handlePosition(id) {
      var hr = document.getElementsByClassName("heart_red");
      if (hr.length != 0) {
        hr[0].classList.remove("heart_red");
      }

      document.getElementById(id).classList.add("heart_red");
    },
    // 按钮市区焦点时取消定位设备
    recoveryPosition(id) {
      document.getElementById(id).classList.remove("heart_red");
    },
    //查詢全部CRT图片信息
    getCrtInfoList() {
      var _this = this;
      CRT.getAllCrt().then((res) => {
        _this.crtInfoList = res.data.data.list;
        if (_this.$route.params.CRTId) {
          _this.crtId = _this.$route.params.CRTId;
          _this.deviceId = _this.$route.params.deviceId;

          _this.crtInfoChanged();
          if (_this.deviceId != _this.CRTId) {
            setTimeout(function () {
              document
                .getElementById(_this.deviceId)
                // .classList.add("heart_beat");
                .classList.add("heart_red");
            }, 2000);
          }
        }
      });
    },
    //展示点位信息
    showDeviceInfo(item) {
      // TODO 展示点位信息
      deviceInfoManagement.getDeviceInfoById(item.deviceInfoId).then((res) => {
        this.deviceInfo = res.data.data.deviceInfoVo;
      });
      deviceInfoManagement
        .getDeviceInfoValueByDeviceId(item.deviceInfoId)
        .then((res) => {
          this.deviceValueInfoList = res.data.data.list;
        });
      this.deviceInfoDialogVisible = true;
    },
    //关闭设备详情的时候清空设备信息详情
    clearDeviceInfo() {
      this.deviceInfo = {};
    },

    /************************图片操作************************/
    //缩放
    //图片缩放
    bbimg() {
      var zoom =
        parseInt(document.getElementById("overburden").style.zoom, 10) || 100;
      zoom += event.wheelDelta / 12;
      if (zoom >= 50)
        document.getElementById("overburden").style.zoom = zoom + "%";
      return false;
    },
    //crt图片改变时的回调函数
    crtInfoChanged() {
      var _this = this;
      this.crtInfoList.forEach((item) => {
        if (item.id == _this.crtId) {
          _this.crtUrl = item.url;
        }
      });

      this.addedDeviceInfoQuery.crtPictureId = this.crtId;
      setTimeout(function () {
        var crt = document.getElementById("crt");
        _this.crtWid = crt.width;
        _this.crtHei = crt.height;
        _this.getCoordinateList();
        _this.getAddedDeviceInfoList();
      }, 1000);
    },
    //根据当前选中的crt图片id获取点位
    getCoordinateList() {
      crtDeviceCoordinate.getCoordinateByCrtId(this.crtId).then((res) => {
        this.crtDeviceCoorcrtDinateList = res.data.data.list;
      });
    },
    /************************************************/

    //返回上一级
    goBack() {
      // window.history.back();
      // window.history.go(-1)
      this.$router.push('/Select')
    },
  },
};
</script>

<style scoped>
.el-dialog__title{
  font-size: 26px;
}
.el-form-item {
  margin-bottom: 0px;
}
.el-row {
  margin-bottom: 20px;
}
.close {
  color: #fff;
  position: absolute;
  right: 1%;
  font-size: 38px;
  font-weight: 300;
  align-self: center;
  cursor: pointer;
}
#overburden {
  position: absolute;
}

#tempText {
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
  margin-top: 250px;
}
#crt {
  width: 1300px;
}
.item-form {
  display: flex;
  flex-wrap: wrap;
  height:50vh;
  overflow-y:scroll;
}
.item-card {
  text-align: center;
  margin: 0.5%;
  width: 24%;
  height: 130px;
  background-color: rgb(194, 228, 240);
  line-height: 35px;
  font-size: 18px;
}
.item-card:hover{
  box-shadow: 1px 1px 10px rgb(114, 113, 113);
}

.green{
  background-color: lightgreen;
}
.yellow{
  background-color: rgb(246, 246, 188);
  /* animation: breathing 1s linear infinite alternate; */

}
.red{
  background-color: rgb(252, 105, 105);
  animation: breathing 0.7s linear infinite alternate;
}
@keyframes breathing {
            0% {
                transform: scale(0.9)
            }
            100% {
                transform: scale(01)
            }
        }
.gery{
  background-color: rgb(204, 207, 204);
}
.blue{
  background-color: lightblue;
}
.el-dialog__title{
  font-size: 19px !important;
}

</style>
